/* HorizontalScroll.css */
.scroll-container {
    width: 100%; /* Set the width of the container */
    overflow-x: auto; /* Enable horizontal scrolling */
  }
  
  .scroll-content {
    display: flex; /* Use flexbox to arrange items in a row */
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    margin: 0;
  }
  
  .item {
    flex: 0 0 auto; /* Prevent items from shrinking and ensure they stay inline */
    width: 200px; /* Set a fixed width for each item */
    height: 100px; /* Set a fixed height for each item */
    background-color: lightblue; /* Just for visualization */
    margin-right: 10px; /* Add some spacing between items */
    text-align: center;
    line-height: 100px; /* Center text vertically */
  }
  